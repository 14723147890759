/** global: Craft */
/** global: Garnish */
/**
 * Element selector modal class
 */
Craft.BaseElementSelectorModal = Garnish.Modal.extend(
  {
    elementType: null,
    elementIndex: null,

    $body: null,
    $selectBtn: null,
    $sidebar: null,
    $sources: null,
    $sourceToggles: null,
    $main: null,
    $search: null,
    $elements: null,
    $tbody: null,
    $primaryButtons: null,
    $secondaryButtons: null,
    $cancelBtn: null,
    $footerSpinner: null,

    init: function (elementType, settings) {
      this.elementType = elementType;
      this.setSettings(settings, Craft.BaseElementSelectorModal.defaults);
      const headingId = 'elementSelectorModalHeading-' + Date.now();

      // Build the modal
      const $container = $('<div/>', {
        class: 'modal elementselectormodal',
        'aria-labelledby': headingId,
      }).appendTo(Garnish.$bod);
      const $headingContainer = $('<div/>', {
        class: this.settings.showTitle ? 'header' : 'visually-hidden',
      }).appendTo($container);
      $('<h2/>', {
        id: headingId,
        text: this.settings.modalTitle,
      }).appendTo($headingContainer);
      const $body = $('<div/>', {
        class: 'body',
      })
        .append($('<div/>', {class: 'spinner big'}))
        .appendTo($container);
      const $footer = $('<div/>', {
        class: 'footer',
      }).appendTo($container);

      if (this.settings.fullscreen) {
        $container.addClass('fullscreen');
        this.settings.minGutter = 0;
      }

      this.base($container, this.settings);

      this.$footerSpinner = $('<div class="spinner hidden"/>').appendTo(
        $footer
      );
      this.$secondaryButtons = $(
        '<div class="buttons left secondary-buttons"/>'
      ).appendTo($footer);
      this.$primaryButtons = $('<div class="buttons right"/>').appendTo(
        $footer
      );
      this.$cancelBtn = $('<button/>', {
        type: 'button',
        class: 'btn',
        text: Craft.t('app', 'Cancel'),
      }).appendTo(this.$primaryButtons);
      this.$selectBtn = $('<button/>', {
        type: 'button',
        class: 'btn disabled submit',
        text: this.settings.selectBtnLabel,
      }).appendTo(this.$primaryButtons);

      this.$body = $body;

      this.addListener(this.$cancelBtn, 'activate', 'cancel');
      this.addListener(this.$selectBtn, 'activate', 'selectElements');
    },

    onFadeIn: function () {
      if (!this.elementIndex) {
        this._createElementIndex();
      } else {
        // Auto-focus the Search box
        if (!Garnish.isMobileBrowser(true)) {
          this.elementIndex.$search.trigger('focus');
        }
      }

      this.base();
    },

    onSelectionChange: function () {
      this.updateSelectBtnState();
    },

    updateSelectBtnState: function () {
      if (this.$selectBtn) {
        if (this.hasSelection()) {
          this.enableSelectBtn();
        } else {
          this.disableSelectBtn();
        }
      }
    },

    hasSelection: function () {
      return (
        this.elementIndex && this.elementIndex.getSelectedElements().length
      );
    },

    enableSelectBtn: function () {
      this.$selectBtn.removeClass('disabled');
    },

    disableSelectBtn: function () {
      this.$selectBtn.addClass('disabled');
    },

    enableCancelBtn: function () {
      this.$cancelBtn.removeClass('disabled');
    },

    disableCancelBtn: function () {
      this.$cancelBtn.addClass('disabled');
    },

    showFooterSpinner: function () {
      this.$footerSpinner.removeClass('hidden');
    },

    hideFooterSpinner: function () {
      this.$footerSpinner.addClass('hidden');
    },

    cancel: function () {
      if (!this.$cancelBtn.hasClass('disabled')) {
        this.hide();
      }
    },

    selectElements: function () {
      if (this.hasSelection()) {
        // TODO: This code shouldn't know about views' elementSelect objects
        if (this.elementIndex.view && this.elementIndex.view.elementSelect) {
          this.elementIndex.view.elementSelect.clearMouseUpTimeout();
        }

        var $selectedElements = this.elementIndex.getSelectedElements(),
          elementInfo = this.getElementInfo($selectedElements);

        this.onSelect(elementInfo);

        if (this.settings.disableElementsOnSelect) {
          this.elementIndex.disableElements(
            this.elementIndex.getSelectedElements()
          );
        }

        if (this.settings.hideOnSelect) {
          this.hide();
        }
      }
    },

    getElementInfo: function ($selectedElements) {
      var info = [];

      for (var i = 0; i < $selectedElements.length; i++) {
        var $element = $($selectedElements[i]);
        var elementInfo = Craft.getElementInfo($element);

        info.push(elementInfo);
      }

      return info;
    },

    show: function () {
      this.updateSelectBtnState();
      this.base();
    },

    onSelect: function (elementInfo) {
      this.settings.onSelect(elementInfo);
    },

    disable: function () {
      if (this.elementIndex) {
        this.elementIndex.disable();
      }

      this.base();
    },

    enable: function () {
      if (this.elementIndex) {
        this.elementIndex.enable();
      }

      this.base();
    },

    getElementIndexParams: function () {
      const params = {
        context: 'modal',
        elementType: this.elementType,
        sources: this.settings.sources,
      };

      if (
        this.settings.showSiteMenu !== null &&
        this.settings.showSiteMenu !== 'auto'
      ) {
        params.showSiteMenu = this.settings.showSiteMenu ? '1' : '0';
      }

      return params;
    },

    _createElementIndex: function () {
      Craft.postActionRequest(
        this.settings.bodyAction,
        this.getElementIndexParams(),
        (response, textStatus) => {
          if (textStatus === 'success') {
            this.$body.html(response.html);

            if (this.$body.has('.sidebar:not(.hidden)').length) {
              this.$body.addClass('has-sidebar');
            }

            // Initialize the element index
            this.elementIndex = Craft.createElementIndex(
              this.elementType,
              this.$body,
              this.getIndexSettings()
            );

            // Double-clicking or double-tapping should select the elements
            this.addListener(
              this.elementIndex.$elements,
              'doubletap',
              function (ev, touchData) {
                // Make sure the touch targets are the same
                // (they may be different if Command/Ctrl/Shift-clicking on multiple elements quickly)
                // and make sure the element is actually selectable
                if (
                  touchData.firstTap.target === touchData.secondTap.target &&
                  this.elementIndex.view.elementSelect.getItemIndex(
                    touchData.firstTap.target
                  ) != -1
                ) {
                  this.selectElements();
                }
              }
            );

            this.on('updateSizeAndPosition', () => {
              this.elementIndex.handleResize();
            });

            this.updateSelectBtnState();
          }
        }
      );
    },

    getIndexSettings: function () {
      return Object.assign(
        {
          context: 'modal',
          modal: this,
          storageKey: this.settings.storageKey,
          criteria: this.settings.criteria,
          disabledElementIds: this.settings.disabledElementIds,
          selectable: true,
          multiSelect: this.settings.multiSelect,
          buttonContainer: this.$secondaryButtons,
          onSelectionChange: () => {
            if (this.elementIndex) {
              this.onSelectionChange();
            }
          },
          onSourcePathChange: () => {
            if (this.elementIndex) {
              this.onSelectionChange();
            }
          },
          hideSidebar: this.settings.hideSidebar,
          defaultSiteId: this.settings.defaultSiteId,
          defaultSource: this.settings.defaultSource,
          defaultSourcePath: this.settings.defaultSourcePath,
          preferStoredSource: this.settings.preferStoredSource,
          showSourcePath: this.settings.showSourcePath,
        },
        this.settings.indexSettings
      );
    },
  },
  {
    defaults: {
      fullscreen: false,
      resizable: true,
      storageKey: null,
      sources: null,
      criteria: null,
      multiSelect: false,
      showSiteMenu: null,
      disabledElementIds: [],
      disableElementsOnSelect: false,
      hideOnSelect: true,
      modalTitle: Craft.t('app', 'Select element'),
      showTitle: false,
      selectBtnLabel: Craft.t('app', 'Select'),
      onCancel: $.noop,
      onSelect: $.noop,
      hideSidebar: false,
      defaultSiteId: null,
      defaultSource: null,
      defaultSourcePath: null,
      preferStoredSource: false,
      showSourcePath: true,
      bodyAction: 'elements/get-modal-body',
      indexSettings: {},
    },
  }
);
